<template>
  <v-container data-app :retain-focus="false">
    <v-row>
      <v-col>
        <v-card>
          <v-row>
            <v-col class="pa-0" cols="8">
              <v-card-title>Push-рассылка: {{ item.title }}</v-card-title>
              <v-card-text class="pb-0">
                <div class="clients-count">Будет отправлено {{ clients_count }}
                  {{ $root.numberWord(clients_count, ['клиенту', 'клиентам', 'клиентам']) }}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="openPushEditModal()"
                       class="mr-2"
                       style="background: #eee !important;">Редактировать
                </v-btn>
                <v-btn @click="openFiltersModal"
                       class="mr-2"
                       style="background: #eee !important;">Фильтры
                </v-btn>
                <v-btn @click="openSendConfirmationModal">Отправить</v-btn>
              </v-card-actions>
              <div v-if="send_statuses.length && items.length"
                   ref="funnelContainer" style="background: #333333;padding: 20px;border-radius: 10px;">
                <vue-funnel-graph :gradient-direction="'horizontal'"
                                  :values="funnelValues"
                                  animated
                                  :colors="funnelColors"
                                  :width="600"
                                  :labels="send_statuses.filter(status => status !== 'попробовать еще раз отправить')"
                                  :sub-labels="['push']"
                                  :height="250"
                />
              </div>
            </v-col>
            <v-col class="pa-0 pr-4 pt-4 flex-column" cols="4">
              <PushNotificationPreview :item="item" class="ml-auto"/>
              <AppPopupNotificationPreview :item="item" class="ml-auto" :small="true"/>
            </v-col>
          </v-row>
          <v-card-title>Отправки</v-card-title>
          <v-card-text>
            <v-data-table :headers="headers" :items="items"
                          :footer-props="{'items-per-page-options':[-1]}">
              <template v-slot:item.client_id="{item}">
                <ClientLink :value="item.client"/>
              </template>
              <template v-slot:item.send_status="{item}">
                {{ send_statuses[+item.send_status] }}
              </template>
              <template v-slot:item.datetime_sent="{item}">
                {{ +item.datetime_sent ? $moment(item.datetime_sent).format('DD.MM.YYYY HH:mm') : '---' }}
              </template>
              <template v-slot:item.datetime_opened="{item}">
                {{ +item.datetime_opened ? $moment(item.datetime_opened).format('DD.MM.YYYY HH:mm') : '---' }}
              </template>
              <template v-slot:item.datetime_button_clicked="{item}">
                {{
                  +item.datetime_button_clicked ? $moment(item.datetime_button_clicked).format('DD.MM.YYYY HH:mm') : '---'
                }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <SendModal ref="send_modal" @on-set-filters="onSetFilters" :clients_count="clients_count" :filters_loading="loading"/>
    <PushModal ref="push_modal" @on-create="get"/>
    <Prompt ref="send_confirmation_modal" title="Подтвердите отправку рассылки" @success="onSuccessConfirmationSend"/>
  </v-container>
</template>

<script>
import PushModal from "@/views/clients/push/PushModal";
import SendModal from "@/views/clients/push/SendModal";
import Prompt from "@/containers/Modals/Prompt";
import ClientLink from "@/components/ClientLink";
import PushNotificationPreview from "@/views/clients/push/components/PushNotificationPreview";
import AppPopupNotificationPreview from "@/views/clients/push/components/AppPopupNotificationPreview";
import {VueFunnelGraph} from 'vue-funnel-graph-js';
import globalMixins from "@/mixins/globalMixins";

export default {
  name: "PushPage",
  components: {
    AppPopupNotificationPreview,
    PushNotificationPreview,
    ClientLink,
    Prompt,
    SendModal,
    PushModal,
    VueFunnelGraph
  },
  mixins: [globalMixins],
  data() {
    return {
      loading: false,
      item: {},
      headers: [
        {value: 'client_id', text: 'Кому'},
        {value: 'send_status', text: 'Статус'},
        {value: 'datetime_sent', text: 'Дата/время отправки'},
        {value: 'datetime_opened', text: 'Дата/время открытия ув-ния'},
        {value: 'datetime_button_clicked', text: 'Дата/время нажатия на кнопку в приле'},
      ],
      items: [],
      clients_count: 0,
      send_statuses: [],
      funnelValues: [],
      funnelColors: [
        ['#FFB178', '#FF3C8E'],
      ],
    }
  },
  computed: {
    store() {
      return this.$store.state;
    },
  },
  methods: {
    openPushEditModal(id = null) {
      this.$refs.push_modal.open({id: id || this.item.id});
      this.$router.push({query: {edit: '1'}}).catch(e => {
      });
    },
    get() {
      this.loading = true;
      this.store.server.request(`mailingListItem/get/${this.$route.params.id}`, {}, (data) => {
        this.loading = false;
        this.item = data.response;
        this.clients_count = data.clients_count;
        this.send_statuses = data.send_statuses;
        this.calcFunnelValues(data.stats);
      }, (data) => {
        this.loading = false;
        this.$root.notify(data.error, 'error');
      })
    },
    openFiltersModal() {
      this.$refs.send_modal.open(this.item.filters);
    },
    onSetFilters(filters) {
      this.store.server.request(`mailingListItem/update/${this.item.id}`, {filters}, () => {
        this.get();
      })
    },
    openSendConfirmationModal() {
      this.$refs.send_confirmation_modal.open(this.item.id);
    },
    onSuccessConfirmationSend() {
      this.store.server.request(`mailingListItem/send/${this.item.id}`, {}, () => {
        this.$refs.send_confirmation_modal.close();
        this.$root.notify('Рассылка успешно отправлена', 'success');
        this.getDispatches();
        this.get();
      }, () => {
        this.$root.notify('Ошибка при отправке рассылки', 'error');
      })
    },
    getDispatches() {
      this.store.server.request('dispatch/get', {mailing_list_id: this.$route.params.id}, (data) => {
        this.items = data.response;
      });
    },
    calcFunnelValues(stats) {
      let values = Array(this.send_statuses.length - 1).fill(0);
      stats.forEach(item => {
        if (+item.send_status === 3) item.send_status = 1;
        if (!values[+item.send_status] && +item.send_status !== 4) values[+item.send_status] = [+item.count];
        else if (+item.send_status === 4) values[3] = [+item.count];
        else values[+item.send_status][0] = +item.count;
      });
      values = values.map(value => !value ? [0] : value);
      this.funnelValues = values;
    }
  },
  mounted() {
    this.get();
    this.getDispatches();
    if (this.$route.query.edit) {
      this.openPushEditModal(this.$route.params.id);
    }
  }
}
</script>

<style scoped>

</style>